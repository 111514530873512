import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { useObserver } from "mobx-react-lite"
// import { useStore } from '@hooks/useStore'
import { FluidImage } from '@atoms/Image'
import Seo from "@atoms/Seo"
import Hero from "@blocks/page/Hero"
import { BreadcrumbList } from "@projects/BreadcrumbList"
import { Section, Title, Paragraph, Column, SmallTitle } from "@blocks/page/Section"
import NewsList from "@blocks/page/NewsList"
import SendMailButton from '@atoms/SendMailButton'
import { ContentFooter } from "@projects/ContentFooter"

import css from "@css/projects/page/p-content.module.styl"

export default function Contact() {
	const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "pageVision__img__hero@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
			}
			message : file(relativePath: { eq: "pageVision__img__message@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 640) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            
            newsData: allMarkdownRemark(
                filter: {frontmatter: {pagetype: {eq: "news"}}}
                sort: { fields: [frontmatter___date], order: DESC }
            ) {
				edges {
					node {
                        excerpt(pruneLength: 160)
                        html
                        frontmatter {
                            public
                            title
                            category
                            date(formatString: "YYYY.MM.DD")
                        }
					}
				}
			}
        }
    `)
	const data = q.newsData.edges
	return (
		<>
			<Seo subtitle={'CONTACT'}/>
			<Hero
				image={q.main01}
				label='お問い合わせ'
				title1='Contact'
				title2=''
				links={[
					{
						label: 'お電話',
						scroll: '#tel'
					}, {
						label: 'FAX',
						scroll: '#fax'
					}
				]}
			/>
			<div className={css.container}>
				<div className={css.inner}>
					<BreadcrumbList title="お問い合わせ" />
					<Section>
						<Paragraph>
							<p>
								当社へのお問い合わせにつきましては、お電話・FAXにて承っております。
							</p>
						</Paragraph>
					</Section>
					<Section id="tel">
						<Title title1="Telephone" title2="お電話でお問い合わせ"/>
						<Paragraph>
							<p className={css.tel}>
								電話番号:<strong>0175-74-2468</strong><br/>
								電話受付:平日9時〜17時
							</p>
						</Paragraph>
					</Section>
					<Section id="fax">
						<Title title1="Fax" title2="FAXでお問い合わせ" />
						<Paragraph>
							<p className={css.fax}>
								Fax番号:<strong>0175-74-2368</strong>
							</p>
						</Paragraph>
					</Section>
					{/* <Section id="mail">
						<Title title1="E-Mail" title2="メールでお問い合わせ" />
						<Paragraph>
							<p className={css.mail}>
								お問合せより3営業日以内に、こちらからの返答がない場合はお手数ですが再度お問合せ下さい。<br/>
								<SendMailButton className={css.mailButton}>
									info@daisen-hogefuga.co.jp
								</SendMailButton>
							</p>
						</Paragraph>
					</Section> */}
				</div>
			</div>
			<ContentFooter/>
		</>
	)
}
