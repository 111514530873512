import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { TransitionLink } from "@atoms/Link"
import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import css from "@css/projects/index/p-news.module.styl"
import * as Anim from "@atoms/animation/Trigger"

export default function PageNews({data, animation})
{
    const q = useStaticQuery(graphql`
        query {
            main01 : file(relativePath: { eq: "indexContent__img__main01@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            main02 : file(relativePath: { eq: "indexContent__img__main02@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            main03 : file(relativePath: { eq: "indexContent__img__main03@2x.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1440) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    console.log('news list',data)
    return(
        <section className={css.container}>
            <div className={css.inner}>
                <div className={css.list}>
                    { data && data.map(( {node}, i)=>{
                        const { slug, title, date, newsId, is_private } = node
                        // const { date, category, title } = node.frontmatter
                        let url = `/news/${slug ? slug : newsId}`
                        if( is_private ){
                            return
                        }
                        const dom1 = () => (
                            <TransitionLink className={css.item} to={url} key={i}>
                                <p className={css.info}>
                                    <span className={css.date}>{date}</span>
                                    <span className={css.category}>NEWS</span>
                                </p>
                                <h4 className={css.title} dangerouslySetInnerHTML={{__html: title}}></h4>
                                {/* <div className={css.content} dangerouslySetInnerHTML={{__html: html}}/> */}
                            </TransitionLink>
                        )
                        const dom2 = () => (
                            <Anim.Trigger key={i}>
                                <TransitionLink className={css.item} to={url}>
                                    <p className={css.info}>
                                        <span className={css.date}>{date}</span>
                                        <span className={css.category}>NEWS</span>
                                    </p>
                                    <h4 className={css.title}>{title}</h4>
                                    {/* <div className={css.content} dangerouslySetInnerHTML={{__html: html}}/> */}
                                </TransitionLink>
                            </Anim.Trigger>
                        )
                        const renderDom = animation ? dom2 : dom1
                        return renderDom()
                    })}
                </div>
            </div>
        </section>
    )
}
