import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import { TransitionLink } from "@atoms/Link"
// import { FluidImage, BackgroundFluidImage } from "@atoms/Image"
import css from "@css/projects/page/p-content.module.styl"

function Title({title1,title2})
{
    return(
        <h3 className={css.title}>
            <span className={css.l1}>{title1}</span>
            { title2 && <span className={css.l2}>{title2}</span> }
        </h3>
    )
}

function SmallTitle({ title1, title2 }) {
    return (
        <h4 className={css.title_small}>
            <span className={css.l1}>{title1}</span>
            { title2 && <span className={css.l2}>{title2}</span> }
        </h4>
    )
}


function Section({ children, ...props }) {
    return (
        <section className={css.section} {...props}>
            {children}
        </section>
    )
}
function Paragraph({ children }) {
    return (
        <div className={css.paragraph}>
            {children}
        </div>
    )
}
function Column({children, type=''}){
    return(
        <div className={css[`column2${type}`]}>
            {children}
        </div>
    )
}

function Figure({ children, caption }) {
    return (
        <figure className={css.figure}>
            {children}
            { caption && <figcaption>{caption}</figcaption> }
        </figure>
    )
}

export { Section, Title, SmallTitle, Paragraph, Column, Figure }